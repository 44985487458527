<template>
  <div class="container my-5" style="max-width: 1400px">
    <h4 class="text-center">ส่งออกข้อมูล</h4>
    <div class="card">
      <div class="card-body px-3 py-4">
        <div class="row">
          <div class="col-12" v-if="lv2">เขต</div>
          <div class="col-6"  v-if="lv2">
            <b-form-select
              v-model="formData.district_type"
              class="form-control"
            >
              <b-form-select-option disabled value=""
                >เลือกประเภทเขต</b-form-select-option
              >
              <b-form-select-option
                v-for="(item, i) in districtType"
                :key="i"
                :value="item.id"
                >{{ item.name }}</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-6" v-if="lv2">
            <b-form-select
              v-if="formData.district_type === 1"
              @change="setProvince($event)"
              v-model="formData.area_code"
              class="form-control"
            >
              <b-form-select-option value=""
                >เลือกเขตสุขภาพ</b-form-select-option
              >

              <b-form-select-option
                v-for="(item, i) in areaCode"
                :key="i"
                :value="item"
                >เลือกเขตสุขภาพที่ {{ item }}</b-form-select-option
              >
            </b-form-select>
            <b-form-select
              v-if="formData.district_type === 2"
              @change="setProvinceGoverment($event)"
              v-model="formData.goverment_code"
              class="form-control"
            >
              <b-form-select-option value=""
                >เลือกเขตราชการ</b-form-select-option
              >

              <b-form-select-option
                v-for="(item, i) in serviceCode"
                :key="i"
                :value="item"
                >เลือกเขตราชการที่ {{ item }}</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-6 mt-2" v-if="lv2">
            <label>จังหวัด</label>
            <b-form-select
              :disabled="!formData.area_code"
              @change="setHospital"
              v-model="formData.province"
              class="form-control"
            >
              <b-form-select-option value=""
                >เลือกจังหวัดทั้งหมด</b-form-select-option
              >

              <b-form-select-option
                v-for="(item, i) in province"
                :key="i"
                :value="item.code"
                >{{ item.name }}</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-6" v-if="lv2"></div>
          <div class="col-6 mt-2" v-if="lv1">
            <label>ประเภทของโรงพยาบาล</label>
            <b-form-select
              @change="setHospital"
              v-model="formData.type"
              class="form-control"
            >
              <b-form-select-option value=""
                >เลือกประเภททั้งหมดของโรงพยาบาล</b-form-select-option
              >

              <b-form-select-option
                v-for="(item, i) in hospitalType"
                :key="i"
                :value="item"
                >{{ item.key }}</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-6 mt-2" v-if="lv1">
            <label>โรงพยาบาล</label>
            <b-form-select
              :disabled="
                !formData.area_code && !formData.province && !formData.type
              "
              v-model="formData.hospcode"
              class="form-control"
            >
              <b-form-select-option value=""
                >เลือกโรงพยาบาลทั้งหมด</b-form-select-option
              >

              <b-form-select-option
                v-for="(item, i) in hospcode"
                :key="i"
                :value="item.hospcode"
                >{{ item.name }}</b-form-select-option
              >
            </b-form-select>
          </div>
          <div class="col-12 mt-2">
            <label>ประเภทแบบบันทึก</label>
            <b-form-select v-model="formData.export_type" class="form-control">
              <b-form-select-option
                v-for="(item, i) in exportTypeList"
                :key="i"
                :value="item.value"
                >{{ item.name }}</b-form-select-option
              >
            </b-form-select>
          </div>
        </div>
      </div>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="dateSelected"
          :aria-describedby="ariaDescribedby"
        >
          <div class="row violence-radio px-3">
            <div class="col-6 py-4 bg-grey-2-theme">
              <label>วันที่เกิดเหตุ</label>
              <div class="row align-items-center">
                <div class="col-1">
                  <b-form-radio value="event"></b-form-radio>
                </div>
                <div class="col-5">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        rounded
                        class="mt-2"
                        dense
                        v-model="computedStartEventDateFormatted"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date.startEventDate"
                      @input="menu = false"
                      locale="th-th"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div class="col-1">-</div>
                <div class="col-5">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        rounded
                        class="mt-2"
                        dense
                        v-model="computedEndEventDateFormatted"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date.endEventDate"
                      @input="menu2 = false"
                      locale="th-th"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
            <div class="col-6 py-4 bg-grey-2-theme">
              <label>วันที่มาโรงพยาบาล</label>
              <div class="row align-items-center">
                <div class="col-1">
                  <b-form-radio value="hospital"></b-form-radio>
                </div>
                <div class="col-5">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        rounded
                        class="mt-2"
                        dense
                        v-model="computedStartHospitalDateFormatted"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date.startHospitalDate"
                      @input="menu3 = false"
                      locale="th-th"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div class="col-1">-</div>
                <div class="col-5">
                  <v-menu
                    v-model="menu4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        rounded
                        class="mt-2"
                        dense
                        v-model="computedEndHospitalDateFormatted"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date.endHospitalDate"
                      @input="menu4 = false"
                      locale="th-th"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
          </div>
        </b-form-radio-group>
      </b-form-group>
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-3">การแสดงผลรหัสบัตรประชาชน</div>
          <div class="col-4">
            <div class="checkbox-border px-2 py-2 my-3">
              <b-form-checkbox
                @change="formData.is_not_encrypt = $event ? 1 : 0"
                :unchecked-value="0"
                disabled-field="true"
              >
                แสดงตามจริง โดยไม่ต้องเข้ารหัสข้อมูล
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 justify-content-end">
      <div class="col-2">
        <button
          @click="show"
          class="btn btn-block btn-border btn-page-violence"
        >
          ส่งออก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
//import DatePicker from "vue2-datepicker";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";
export default {
  created() {
    // this.convertDate("startEventDate");
    // this.convertDate("endEventDate");
    // this.convertDate("startHospitalDate");
    // this.convertDate("endHospitalDate");
    let role = this.user.role;
    if (role == 1){
      this.lv1 = true;
      this.lv2 = true;
    }
    // else if (role == 2){
    //   this.lv1 = true;
    //   this.lv2 = false;
    // }
    else if (role == 3){
      this.lv1 = false;
      this.lv2 = false;
      this.formData.only_hosp = this.user.agency_code
    }
  },
  computed: {
    computedStartEventDateFormatted() {
      if (!this.date.startEventDate) return null;
      const [year, month, day] = this.date.startEventDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedEndEventDateFormatted() {
      if (!this.date.endEventDate) return null;
      const [year, month, day] = this.date.endEventDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedStartHospitalDateFormatted() {
      if (!this.date.startHospitalDate) return null;
      const [year, month, day] = this.date.startHospitalDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedEndHospitalDateFormatted() {
      if (!this.date.endHospitalDate) return null;
      const [year, month, day] = this.date.endHospitalDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    ...mapState({
      province: (state) => {
        return state.MasterData.province;
      },
      hospcode: (state) => {
        return state.MasterData.hospcode;
      },
      user: (state) => {
        return state.Auth.user;
      },
    }),
  },
  data() {
    return {
      lv1:true,
      lv2:true,
      dateSelected: "event",
      selectedType: 1,
      districtType: [
        { id: 1, name: "เขตสุภาพ" },
        { id: 2, name: "เขตราชการ" },
      ],
      date: {
        startEventDate: "",
        endEventDate: "",
        startHospitalDate: "",
        endHospitalDate: "",
      },
      formData: {
        is_not_encrypt: 0,
        export_type: "violence",
        district_type: 1,
        type: "",
        code: "",
        province: "",
        area_code: "",
        goverment_code: "",
        hospcode: "",
        only_hosp: ""
      },
      areaCode: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      serviceCode: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
      ],
      hospitalType: [
        { key: "โรงพยาบาลศูนย์_A", value: "A" },
        { key: "โรงพยาบาลทั่วไป_S", value: "S" },
        { key: "โรงพยาบาลทั่วไปขนาดเล็ก_M1", value: "M1" },
        { key: "โรงพยาบาลชุมชนแม่ข่าย_M2", value: "M2" },
        { key: "โรงพยาบาลชุมชนขนาดใหญ่_F1", value: "F1" },
        { key: "โรงพยาบาลชุมชนขนาดกลาง_F2", value: "F2" },
        { key: "โรงพยาบาลชุมชนขนาดเล็ก_F3", value: "F3" },
      ],
      exportTypeList: [
        { name: "แบบบันทึกการลงข้อมูลผู้ที่ถูกกระทำรุนแรง", value: "violence" },
        {
          name: "แบบบันทึกการลงข้อมูลผู้ที่ตั้งครรภ์ไม่พึงประสงค์",
          value: "pregnant",
        },
        {
          name: "แบบบันทึกการลงข้อมูลผู้ที่ถูกกระทำรุนแรง และตั้งครรภ์ไม่พึงประสงค์",
          value: "all",
        },
      ],
    };
  },
  methods: {
    initDate(prop) {
      if (!this.date[prop]) {
        this.convertDate(prop);
      }
    },
    convertDate(name) {
      let christianYear = moment().format("YYYY");
      let buddhishYear = (parseInt(christianYear) + 543).toString();
      let thaiDate = moment()
        .format(
          "DD-MM-YYYY"
            .replace("YYYY", buddhishYear)
            .replace("YY", buddhishYear.substring(2, 4))
        )
        .replace(christianYear, buddhishYear);
      this.date[name] = thaiDate;
    },
    show() {
      Swal.fire({
        title: "กำลังแสดงข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: async () => {
          Swal.showLoading();
          await this.$store
            .dispatch("Form/exportForm", {
              is_not_encrypt: this.formData.is_not_encrypt,
              type: this.formData.export_type,
              hospcode: this.formData.hospcode,
              start_event_date:
                this.dateSelected === "event"
                  ? this.formatDate(this.date.startEventDate)
                  : "", // moment(this.date.startEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              end_event_date:
                this.dateSelected === "event"
                  ? this.formatDate(this.date.endEventDate)
                  : "", //moment(this.date.endEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              start_hospital_date:
                this.dateSelected === "hospital"
                  ? this.formatDate(this.date.startHospitalDate)
                  : "", //moment(this.date.startHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              end_hospital_date:
                this.dateSelected === "hospital"
                  ? this.formatDate(this.date.endHospitalDate)
                  : "", //moment(this.date.endHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              only_hosp : this.user.role == 3 ? this.formData.only_hosp : ''
            })
            .then((response) => {
              window.open(response);
              Swal.close();
            })
            .catch(() => {
              Swal.close();
            });
        },
      });
    },
    formatDate(date) {
      return date
        ? moment(date, "YYYY-MM-DD").add(543, "years").format("YYYY-MM-DD")
        : "";
    },
    setHospital() {
      this.$store.dispatch("MasterData/setHospCode", {
        type: this.formData.type.value,
        province: this.formData.province,
        code: this.formData.area_code,
      });
    },
    setProvince(event) {
      this.$store.dispatch("MasterData/setProvinceByAreaCode", event);
      this.$store.dispatch("MasterData/setHospCode", {
        type: this.formData.type.value,
        province: this.formData.province,
        code: this.formData.area_code,
      });
    },
    setProvinceGoverment(event) {
      this.$store.dispatch("MasterData/setProvinceByGovernmentCode", event);
    },
  },
};
</script>

<style>
</style>
